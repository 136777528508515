import React from 'react';
import Layout from 'components/Layout';
import { graphql } from 'gatsby';
import { Container, Heading, ProductsGrid, Button } from 'components';
import ProductContext from 'context/ProductContext';
import { documentToHtmlString } from '@contentful/rich-text-html-renderer';

function StorePage({ data }) {
  const { products } = React.useContext(ProductContext);
  const richText = JSON.parse(data.contentfulPages?.body.raw);

  console.log(products);

  return (
    <Layout pageTitle="Store">
      <Container width="lg:max-w-5xl">
        <div className="lg:px-10 text-center">
          <Heading className="text-center">
            {data.contentfulPages?.title}
          </Heading>
          <div
            className="rich-text"
            dangerouslySetInnerHTML={{
              __html: documentToHtmlString(richText),
            }}
          />
        </div>
        <ProductsGrid products={products} />
      </Container>
    </Layout>
  );
}

export default StorePage;

export const query = graphql`
  query storePageQuery {
    contentfulPages(slug: { eq: "store" }) {
      title
      slug
      body {
        raw
      }
    }
  }
`;
